import React, { Fragment, useEffect } from "react"
import FormRadio from "../Elements/Form/FormRadio"
import dispensingChannel from "./utils/dispensingChannel.json"

const OrderDetails = ({ values, setFieldValue, dispensingChannels = [] }) => {
  let formFields = [
    {
      type: "radio",
      options: dispensingChannel,
      followUpQuestions: ["otherDispensingChannel"],
      label:
        "If your preferred dispensing channel encounters challenges delivering your order, do you want to:",
      name: "dispensingChannel",
      isFollowUpQuestion: false,
    },
    {
      type: "radio",
      options: dispensingChannels.filter(
        (dispensingChannel) => dispensingChannel !== values?.courierOption
      ),
      isRequired: true,
      hideOptional: true,
      label: "Please select an alternative dispensing channel",
      name: "otherDispensingChannel",
      isFollowUpQuestion: true,
      referenceQuestion: "dispensingChannel",
      referenceAnswer: "(Recommended)SwitchToAnotherDispensingChannel",
    },
  ]

  useEffect(() => {
    setFieldValue("otherDispensingChannel", "")
  }, [values.courierOption])

  return (
    <Fragment>
      <FormRadio
        name="dispensingChannel"
        title="If your preferred dispensing channel encounters challenges delivering your order, do you want to:"
        options={dispensingChannel}
        value={values?.dispensingChannel}
        isRequired
        followUpQuestions={["otherDispensingChannel"]}
        formFields={formFields}
        formValues={values}
      />
    </Fragment>
  )
}

export default OrderDetails
