import React from "react"
import classNames from "classnames"

import invalidFormMessages from "./utils/invalidFormMessages.json"

const InvalidFormMessage = ({ children, color, name, className }) => (
  <article className={classNames(`message is-${color} ${className || ""}`)}>
    <div className="message-body">{children || invalidFormMessages[name]}</div>
  </article>
)

export default InvalidFormMessage
