/*
 * MedGrocer logo only
 */
import React, { useState, useContext, Fragment, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

import AuthActions from "./Navbar/AuthActions"
import AuthActionsMobile from "./Navbar/AuthActionsMobile"

import styles from "./utils/layout.module.scss"
import { AppContext } from "../../context/AppContext"
import useNavbarIcons from "./services/useNavbarIcons"
import { getSignedInUser } from "../../components/Auth/services/user"
import { handleSignOut } from "../../components/Auth/services/signout"

import doctorIcon from "../../../static/images/icons/doctor.svg"
import drugsIcon from "../../../static/images/icons/drugs.svg"
import healthcareIcon from "../../../static/images/icons/healthcare.svg"
import profileIcon from "../../../static/images/icons/user.svg"
import { GATSBY_HAS_AUTH_ENABLED } from "gatsby-env-variables"

const Navbar = ({ path, removeLinks, isSignInPage }) => {
  const data = useNavbarIcons()

  const navbarItems = !!removeLinks
    ? []
    : [
        {
          name: "View Profile",
          link: "/profile",
          icon: profileIcon,
          isShownSignedOut: false,
          isShownSignedIn: true,
        },
        {
          name: "Patient Enrollment",
          link: "/enroll-patient",
          icon: healthcareIcon,
          isShownSignedOut: true,
          isShownSignedIn: false,          
        },
        {
          name: "Doctor Enrollment",
          link: "/enroll-doctor",
          icon: doctorIcon,
          isShownSignedOut: true,
          isShownSignedIn: false,  
        },
        {
          name: "Order Medicines",
          link: "/epharmacy/order",
          icon: drugsIcon,
          isShownSignedOut: true,
          isShownSignedIn: true,  
        },
      ]
  
  const webContentItems = !!removeLinks 
  ? []
  : [
      {
        name: "Help Center",
        link: "/help-center",
      },
      {
        name: "Terms and Conditions",
        link: "/terms-and-conditions",
      },
      {
        name: "Privacy Policy",
        link: "/privacy-policy",
      },
    ]

  const IS_AUTH_ENABLED = GATSBY_HAS_AUTH_ENABLED === "enabled" ? true : false

  const logoLongWhite = data.logoLongWhite.childImageSharp.fixed
  const logoLongGreen = data.logoLongGreen.childImageSharp.fixed
  const logoCircleWhite = data.logoCircleWhite.childImageSharp.fixed

  const { state, dispatch } = useContext(AppContext)
  const [isMenuActive, setMenuActive] = useState(false)
  const [user, setUser] = useState({ roles: [] })

  useEffect(() => {
    const USER_DATA = getSignedInUser()?.userData
    setUser(USER_DATA)
  }, [])

  const handleMenuActive = () => setMenuActive(!isMenuActive)
  const showCart = () =>
    dispatch({
      type: "SHOW_CART",
    })

  const medicinesInCart =
    state.epharmacy.medicines.length > 0
      ? state.epharmacy.medicines.length
      : null

  const epharmacyPath = path.includes("epharmacy")
  const completedPath = path.includes("completed")

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-light is-primary">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Link to="/">
            <div className={classNames(styles["navbar__brandLogos"])}>
              <Img fixed={logoLongWhite} className="is-hidden-mobile" />
              <Img fixed={logoCircleWhite} className="is-hidden-tablet" />
            </div>
          </Link>
          {!removeLinks && (
            <div className={styles["navbar__burgerContainer"]}>
              {IS_AUTH_ENABLED && (
                <AuthActionsMobile user={user} isMenuActive={isMenuActive} isSignInPage={isSignInPage} />
              )}
              {epharmacyPath && !completedPath && (
                <a
                  className="icon mr-1"
                  aria-label="menu"
                  aria-expanded="false"
                  onClick={showCart}
                >
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {medicinesInCart && (
                    <span
                      className={classNames(
                        "tag is-small is-rounded is-warning",
                        styles["navbar__badge"]
                      )}
                    >
                      {medicinesInCart}
                    </span>
                  )}
                </a>
              )}
              <a
                onClick={handleMenuActive}
                aria-label="menu"
                aria-expanded="false"
                className="icon"
              >
                <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
              </a>
            </div>
          )}
        </div>
        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div className={classNames("navbar-end is-flex is-flex-direction-column px-1")} >
            <a
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="is-clickable is-align-self-flex-end mr-1 mt-1 is-hidden-desktop"
              role="button"
              onKeyDown={(event) => {
                if (event.key === "Enter") handleMenuActive()
              }}
              tabIndex={0}
            >
              <div className={classNames("has-text-white", styles["navbar__closeButton"])}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </a>
            <div className="w-full is-flex is-justify-content-center my-1 is-hidden-desktop">
              <Img fixed={logoLongGreen}/>
            </div>
            {navbarItems.map((item) => {
              if (!!user?.authUid === true && item.isShownSignedIn === true) {
                return (
                  <Link to={item.link} key={item.name}>
                    <div className={classNames("is-hidden-desktop", styles["navbar__menuItem"])}>
                      <div className={classNames(styles["navbar__menuImageContainer"])}>
                        <img src={item.icon} className={classNames(styles["navbar__menuIcon"])} />
                      </div>
                      <p className="has-text-primary is-size-5 m-0 ml-1">{item.name}</p>
                    </div>
                  </Link>
                );
              }
              else if (!!user?.authUid === false && item.isShownSignedOut === true) {
                return (
                  <Link to={item.link} key={item.name}>
                    <div className={classNames("is-hidden-desktop", styles["navbar__menuItem"])}>
                      <div className={classNames(styles["navbar__menuImageContainer"])}>
                        <img src={item.icon} className={classNames(styles["navbar__menuIcon"])} />
                      </div>
                      <p className="has-text-primary is-size-5 m-0 ml-1">{item.name}</p>
                    </div>
                  </Link>
                );
              }
              return null;
            })}
            {IS_AUTH_ENABLED && !removeLinks && (
              <AuthActions user={user} handleSignOut={handleSignOut} isSignInPage={isSignInPage} />
            )}
            {webContentItems.map((item) => (
              <Link to={item.link}>
                <p className={classNames("is-size-6 has-text-dark is-hidden-desktop", styles["navbar__footerItem"])}>{item.name}</p>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      <section
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
