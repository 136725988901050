import moment from "moment"

import { generateJWT } from "../../../services/jwt"
import { firebaseApi } from "../../../services/firebase/firebaseApi"

export const getAnnouncements = async (dateToday) => {
  const JWT_OBJECT = await generateJWT()
  const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token

  let request = await firebaseApi({
    accessToken: ACCESS_TOKEN,
  }).post(`:runQuery`, {
    structuredQuery: {
      select: {
        fields: [
          {
            fieldPath: "message",
          },
          {
            fieldPath: "startDate",
          },
          {
            fieldPath: "endDate",
          },
          {
            fieldPath: "subdomains",
          },
        ],
      },
      from: [
        {
          collectionId: "announcements",
        },
      ],
      where: {
        fieldFilter: {
          field: {
            fieldPath: "subdomains",
          },
          op: "EQUAL",
          value: {
            stringValue: process.env.GATSBY_WEBSITE_SUBDOMAIN,
          },
        },
      },
    },
  })

  let requestData = request?.data
  let filteredData = []
  for (let i = 0; i < requestData?.length; i++) {
    let startDate = moment(
      requestData[i]?.document?.fields?.startDate?.timestampValue
    )

    let endDate = moment(
      requestData[i]?.document?.fields?.endDate?.timestampValue
    )

    let isBetween = moment(dateToday).isBetween(startDate, endDate)

    if (isBetween) {
      filteredData.push(requestData[i])
    }
  }

  let announcement = filteredData[0]?.document?.fields?.message?.stringValue
  return announcement
}
