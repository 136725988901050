import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import styles from "../utils/epharmacy.module.scss"
import RadioContainerButton from "./RadioContainerButton"

const RadioContainer = ({ isActive, onClick, label, logo, children }) => {
  return (
    <div
      className={classNames(
        "mx-1",
        styles[`shippingDetails__shippingOption${isActive ? "Active" : ""}`]
      )}
      onClick={onClick}
    >
      <div className="columns is-vcentered is-mobile ml-1 ml-0-mobile">
        <RadioContainerButton isSelected={isActive} />
        <div
          className={classNames(
            "is-flex column is-2 pr-0",
            styles["shippingDetails__shippingOptionContainer"]
          )}
        >
          <Img fixed={logo} />
          {label && <p className="is-size-6 has-text-centered">{label}</p>}
        </div>
        <div className="column pl-0">{children}</div>
      </div>
    </div>
  )
}

export default RadioContainer
