import React, { Fragment, useState, useEffect } from "react"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"

import { useAddresses } from "./hooks/useAddresses"
import AddressTypeChoices from "./Address/AddressTypeChoices.js"

const INPUT_STREET_PLACEHOLDER = "Unit/House No., Building, Street, Barangay"
const SELECT_PROVINCE_PLACEHOLDER = "Select province"
const SELECT_CITY_PLACEHOLDER = "Select city"
const SELECT_BARANGAY_PLACEHOLDER = "Select barangay"

const Address = ({
  isRequired,
  fieldNames = {},
  values = {},
  isNationwide,
  helper,
  setFieldValue,
  hasNoStreetAddress,
  onChange,
}) => {
  const { addressType, streetAddress, province, city, barangay } = fieldNames
  const [listOfProvince, setProvinces] = useState([])
  const [listOfCity, setListOfCity] = useState([])
  const [listOfBarangays, setListOfBarangays] = useState([])

  const addresses = useAddresses()

  useEffect(() => {
    initializeProvinces()
    const filteredProvince = addresses.filter(
      (loc) => loc.Province === values.province?.value
    )?.[0]
    setListOfCity(sortCities(filteredProvince?.Cities || []))
    const filteredCity = filteredProvince?.Cities?.filter?.(
      (location) => location.City === values?.city?.value
    )?.[0]
    setListOfBarangays(
      filteredCity?.Barangays?.sort((a, b) => a.localeCompare(b))?.map(
        (barangay) => ({
          label: barangay,
          value: barangay,
        })
      ) || []
    )
    //eslint-disable-next-line
  }, [])

  const initializeProvinces = () => {
    let options = addresses.map(remapProvinces)
    const metroManila = options.filter(({ value }) => value === "Metro Manila")
    if (isNationwide) setProvinces(options)
    else setProvinces(metroManila)
  }

  const remapProvinces = ({ Province }) => ({
    value: Province,
    label: Province,
  })
  const sortCities = (cities) => {
    const sortedCities = cities
      .map(({ City, Barangays }) => ({
        value: City,
        label: City,
        barangays: Barangays,
      }))
      .sort((a, b) => {
        return a.value > b.value ? 1 : -1
      })

    return sortedCities
  }
  const handleOnChange = ({ value }, setFieldValue) => {
    const { Cities } = addresses.filter((loc) => loc.Province === value)[0]
    setListOfCity(sortCities(Cities))
    setFieldValue(city || "city", { value: "" })
    if (onChange?.province) onChange.province({ setFieldValue, value })
  }

  const handleOnCityChange = (cityObject, setFieldValue) => {
    if (
      cityObject &&
      cityObject.value !== null &&
      cityObject.value !== undefined
    ) {
      const { value } = cityObject
      const selectedCity = listOfCity.find((item) => item.value === value)

      setListOfBarangays(
        selectedCity?.barangays
          ?.sort((a, b) => a.localeCompare(b))
          .map((item) => ({
            value: item,
            label: item,
          }))
      )
      setFieldValue(barangay || "barangay", { value: "" })
    }
  }

  return (
    <Fragment>
      {addressType && (
        <AddressTypeChoices
          addressTypeName={addressType}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}
      {!hasNoStreetAddress && (
        <FormInput
          name={streetAddress || "streetAddress"}
          label="Street Address"
          helper={helper}
          placeholder={INPUT_STREET_PLACEHOLDER}
          isRequired={isRequired}
          hideOptional={!isRequired}
          maxLength={100}
        />
      )}
      <FormSelect
        name={province || "province"}
        label="Province"
        isRequired={isRequired}
        value={values.province.value ? values.province : null}
        placeholder={SELECT_PROVINCE_PLACEHOLDER}
        options={listOfProvince}
        onChange={handleOnChange}
        hideOptional={!isRequired}
      />
      <FormSelect
        name={city || "city"}
        label="City"
        placeholder={SELECT_CITY_PLACEHOLDER}
        value={values.city?.value ? values.city : null}
        isRequired={isRequired}
        options={listOfCity}
        isSelectable
        hideOptional={!isRequired}
        onChange={handleOnCityChange}
      />
      {barangay ? (
        <FormSelect
          name={barangay || "barangay"}
          label="Barangay"
          placeholder={SELECT_BARANGAY_PLACEHOLDER}
          value={values.barangay.value ? values.barangay : null}
          isRequired={isRequired}
          options={listOfBarangays}
          hideOptional={!isRequired}
          isSelectable
        />
      ) : null}
    </Fragment>
  )
}

export default Address
