import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

const AuthActionsMobile = ({ user, isMenuActive, isSignInPage }) => {
  if (user?.authUid)
    return null
  return (
    (!isSignInPage && 
      <Link
        className={classNames(
          "navbar-item button mr-1 px-1 mb-0 is-transparent has-text-primary is-inverted is-outlined is-hidden-tablet is-hidden-mobile",
          {
            "is-hidden": isMenuActive,
          }
        )}
        to="/sign-in"
      >
        Sign in
      </Link>
    )
  )
}

export default AuthActionsMobile
