import React, { Fragment } from "react"

import Section from "../../Elements/Section"
import CourierOptions from "./CourierOptions"

const CourierOptionField = ({
  values,
  isNationwide,
  setFieldValue,
  baseRoute,
  configurationChannels,
  module,
}) => {
  return (
    <CourierOptions
      values={values}
      setFieldValue={setFieldValue}
      isNationwide={isNationwide}
      nextRoute={baseRoute}
      configurationChannels={configurationChannels}
      module={module}
    />
  )
}

const DispensingChannelOptionsSection = ({
  values,
  isNationwide,
  setFieldValue,
  baseRoute,
  configurationChannels,
  state,
  module,
}) => {
  return (
    <Fragment>
      <Section
        title="Dispensing Channel Options"
        className="my-1"
        subtitle={
          state?.epharmacy?.hasMedGrocerOption
            ? "Select MedGrocer to avail of the free nationwide delivery offer"
            : ""
        }
      >
        <CourierOptionField
          values={values}
          isNationwide={isNationwide}
          setFieldValue={setFieldValue}
          baseRoute={baseRoute}
          configurationChannels={configurationChannels}
          module={module}
        />
      </Section>
    </Fragment>
  )
}

export default DispensingChannelOptionsSection
