import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import RadioContainerButton from "./RadioContainerButton"

import styles from "../utils/epharmacy.module.scss"

const PaymentOption = ({ values, paymentOption, setFieldValue, data }) => (
  <div
    className={classNames(
      styles[
        `shippingDetails__paymentOption${
          values.paymentOption === paymentOption.label ? "Active" : ""
        }`
      ]
    )}
    onClick={() => {
      setFieldValue("paymentOption", paymentOption.label)
    }}
  >
    <div
      className={classNames(
        "columns is-vcentered is-mobile ml-1 ml-0-mobile",
        styles["shippingDetails__paymentOptionContainer"]
      )}
    >
      <RadioContainerButton
        isSelected={values.paymentOption === paymentOption.label}
      />
      <div className="column is-1 mx-1 mr-3-mobile ml-1-mobile">
        <Img fixed={data[paymentOption.image]?.childImageSharp.fixed} />
      </div>
      <div className="column">
        <p className="is-size-6 has-text-left">{paymentOption.label}</p>
        {!!paymentOption?.helper ? (
          <p className="help has-text-left">{paymentOption.helper}</p>
        ) : null}
      </div>
    </div>
  </div>
)

export default PaymentOption
