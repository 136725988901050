import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import classNames from "classnames"
import axios from "axios"

import SEO from "./SEO"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Loading from "../Elements/Loading"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import UnderMaintenance from "../StaticPages/UnderMaintenance"

import {
  GATSBY_ORDER_AS_GUEST,
  GATSBY_HAS_AUTH_ENABLED,
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
  GATSBY_GET_ORDER_DETAILS_WEBHOOK,
} from "gatsby-env-variables"
import {
  hasSignedInUser,
  getSignedInUser,
  hasVerifiedName,
} from "components/Auth/services/user"
import { isBrowser } from "services/general"
import styles from "./utils/layout.module.scss"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true, banner: true },
  path,
  isPrivate,
  isPatient,
  underMaintenance,
  removeLinks,
  isSignInPage,
  isVerifyNamePage,
  hasHexagons,
  locationState,
}) => {
  let pathArray = path ? path.split("/") : []
  let isOrderingAsGuest = false
  const IS_AUTH_ENABLED = GATSBY_HAS_AUTH_ENABLED === "enabled"
  
  // Loading componenent is only used with verify name right now
  const [loading, setLoading] = useState(!!isVerifyNamePage) 

  if (GATSBY_ORDER_AS_GUEST === "enabled") {
    if (isBrowser()) {
      isOrderingAsGuest =
        window.sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY) ===
        GATSBY_ORDER_AS_GUEST_VALUE
          ? true
          : false
    }
  }
  
  const navigateToVerifyName = async () => {
    const { zendeskId } = locationState
    let subtype = ""
    const getOrderDetails = async () => {
      let response = await axios.post(GATSBY_GET_ORDER_DETAILS_WEBHOOK, {
        zendeskId,
      })
      subtype = response.data.subtype
      const lastNameToValidate = subtype === "Special" ? response.data.doctorLastName : response.data.lastName 
      locationState = { 
        ...locationState, 
        lastName: lastNameToValidate 
        // doctorLastName: response.data.doctorLastName 
      }
    }
    if (!!zendeskId) await getOrderDetails()
    if (subtype === "Special") {
      locationState.lastName ? navigate("/verify-doctor", { state: locationState }) : module ? navigate(`/${module}/invalid`) : navigate("/proof-of-payment/invalid")
    } else {
      locationState.lastName ? navigate("/verify-name", { state: locationState }) : module ? navigate(`/${module}/invalid`) : navigate("/proof-of-payment/invalid")
    }
  }

  useEffect(() => {
    if (IS_AUTH_ENABLED && !isOrderingAsGuest) {
      if (isPrivate && isBrowser() && !hasSignedInUser()) {
        locationState ? navigate("/sign-in", { state: locationState }) : navigate("/sign-in")
      }
      if (isVerifyNamePage && isBrowser() && locationState.zendeskId && !hasVerifiedName()) {
        setLoading(true)
        navigateToVerifyName()
      }
      if (isVerifyNamePage && isBrowser() && hasVerifiedName()) {
        setLoading(false)
      }
      if (isPatient && isBrowser() && hasSignedInUser()) {
        let user = getSignedInUser()
        let hasRole = user?.userData?.roles.find(
          (role) => role.subdomain === GATSBY_AWS_S3_BUCKET_NAME // GATSBY_WEBSITE_SUBDOMAIN
        )
        if (!hasRole) navigate("/enroll-patient")
      }
    }
    if (isPatient && isBrowser() && hasSignedInUser()) {
      let user = getSignedInUser()
      let hasRole = user?.userData?.roles.find(
        (role) =>
          role.subdomain === GATSBY_AWS_S3_BUCKET_NAME &&
          role.status === "active"
      )
      if (!hasRole) navigate("/enroll-patient")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (underMaintenance) return <UnderMaintenance />

  if (!!loading) {
    return (
      <Fragment>
        <SEO title={seoTitle} />
        <Navbar path={pathArray} removeLinks={removeLinks} isSignInPage={isSignInPage} />
        <Loading />
      </Fragment>
    )
  } 

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <Navbar path={pathArray} removeLinks={removeLinks} isSignInPage={isSignInPage} />
      
          {display.banner && <Notification />}
          {hasHexagons && (
            <Fragment>
              <div className={classNames(styles["hexagonPrimary"])}></div>
              <div className={classNames(styles["hexagonSecondary"])}></div>
            </Fragment>
          )}
          {title && (
            <h2 className="has-text-centered mb-0 has-text-primary mx-2-mobile pb-3">
              {title}
              <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-6 mx-0-mobile">
                {subtitle}
              </h4>
            </h2>
          )}
          <main className="mb-3">{children}</main>
          {display.helpCenterBanner && <HelpCenterBanner />}
          {display.footer && <Footer />}

    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
