import React, { Fragment, useEffect, useState } from "react"
import moment from "moment"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

import { isBrowser } from "services/general"
import {
  GATSBY_ORDER_AS_GUEST,
  GATSBY_AUTH_NOTIFICATION,
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
} from "gatsby-env-variables"
import { getAnnouncements } from "./services/getAnnouncements"

const Notification = () => {
  let dateToday = moment().format("YYYY-MM-DD hh:mm:ss")
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()
  const [announcement, setAnnouncement] = useState(null)

  const getTodaysAnnouncement = async () => {
    const banner = await getAnnouncements(dateToday)
    setAnnouncement(banner)
  }

  useEffect(() => {
    getTodaysAnnouncement()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let notificationMessage = null

  let isOrderingAsGuest = false
  if (GATSBY_ORDER_AS_GUEST) {
    if (isBrowser()) {
      isOrderingAsGuest =
        window.sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY) ===
        GATSBY_ORDER_AS_GUEST_VALUE
          ? true
          : false
    }
  }

  let notificationBlurb =
    "For any inquiries, send us a message with a description of your concern through careplus@medgrocer.com or "
  if (isOrderingAsGuest) {
    notificationBlurb = `The Order as Guest page will only be available until ${GATSBY_AUTH_NOTIFICATION}. If you experience trouble signing in, please text or call us at `
  }

  notificationMessage = (
    <Fragment>
      <p>
        {notificationBlurb}
        <a href={`tel:${process.env.GATSBY_PHONE_NUMBER}`}>
          {process.env.GATSBY_PHONE_NUMBER}
        </a>
        .
      </p>

      {!!announcement ? (
        <span
          className="helper is-size-5"
          dangerouslySetInnerHTML={{ __html: announcement }}
        />
      ) : null}
    </Fragment>
  )

  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
