import React, { Fragment } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/layout.module.scss"

const AuthActions = ({ user, handleSignOut, isSignInPage }) => {
  if (user?.authUid)
    return (
      <Fragment>
        <div className="navbar-item is-hidden-desktop">
          <button
            className="button is-secondary is-fullwidth my-1 is-centered is-hidden-desktop"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
        <div className="navbar-item has-dropdown is-hoverable is-hidden-touch is-hidden-mobile">
          <span className="navbar-link">
            <FontAwesomeIcon icon={faUser} />
            <span className="ml-1">
              {" "}
              {`${user?.firstName} ${user?.lastName}`}
            </span>
          </span>
          <div className="navbar-dropdown">
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"],
                styles["navbar__dropdownButton"]
              )}
              onClick={() => navigate("/profile")}
            >
              Profile
            </span>
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"],
                styles["navbar__dropdownButton"]
              )}
              onClick={handleSignOut}
            >
              Sign Out
            </span>
          </div>
        </div>
      </Fragment>
    )
  return (
    (!isSignInPage && 
      <Fragment>
        <Link className="button is-white has-text-primary is-hidden-mobile" to="/sign-in">
          Sign in
        </Link>
        <Link className="button is-secondary is-fullwidth my-1 is-centered is-hidden-desktop" to="/sign-in">
          Sign In
        </Link>
      </Fragment>
    )
  )
}

export default AuthActions
