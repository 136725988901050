import React from "react"
import FormSelect from "../../Elements/Form/FormSelect"
import FormTextArea from "../../Elements/Form/FormTextarea"
import Section from "../../Elements/Section"

const FeedbackSection = ({
  values,
  handleChange,
}) => {
  const SCORE_ARRAY_LENGTH = 10

  return (
    <Section title="Feedback" className="my-1">
      <FormSelect
        name="rating"
        label="On a scale of 1 to 10, how satisfied are you with the enrollment and ordering process?"
        value={values?.rating}
        options={Array.apply(null, Array(SCORE_ARRAY_LENGTH)).map(
          (value, index) => ({
            label: SCORE_ARRAY_LENGTH - index,
            value: SCORE_ARRAY_LENGTH - index,
          })
        )}
        isRequired
      />
      <FormTextArea
        name="feedback"
        label="What improvements would you suggest for the CarePlus enrollment and ordering process?"
        isRequired
      />
    </Section>
  )
}

export default FeedbackSection
