import { camelizeKeys } from "humps"
import { isBrowser } from "../../../services/general"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    const USER = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]

    return USER
  }
}

export const getSignedInUser = () => {
  if (isBrowser()) {
    let authUser = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]
    authUser = JSON.parse(sessionStorage.getItem(authUser))
    let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")))
    let addresses = JSON.parse(sessionStorage.getItem("addresses"))

    return { ...authUser, userData, addresses }
  }
}

export const hasVerifiedName = () => {
  if (isBrowser()) {
    let hasVerifiedName = JSON.parse(sessionStorage.getItem("hasVerifiedName"))
    
    return hasVerifiedName
  }
}