import React from "react"
import PropTypes from "prop-types"

import styles from "./utils/elements.module.scss"

/**
 * @param {number} size integer, defaults to 2
 */

const Loading = () => (
  <div className={styles["loading"]} style={{ fontSize: `5rem` }} />
)

Loading.propTypes = {
  size: PropTypes.string,
}

export default Loading
