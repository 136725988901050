import React, { useRef, useEffect } from "react"
import { Field, FieldArray, ErrorMessage } from "formik"
import { camelize } from "humps"
import classNames from "classnames"

import styles from "../utils/elements.module.scss"
import { generateFormField, getFollowUpQuestionData } from "./services/form"

/**
 ** Checkbox group linked in formik.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'values': Object [],
 **               'label': String,
 **               'options': Object [],
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormCheckbox = ({
  name,
  options,
  values,
  value = [],
  onChange,
  title,
  disabled,
  isRequired,
  hideOptional,
  followUpQuestions = [],
  formFields,
  formValues,
  setFieldValue,
  isFollowUpQuestion,
  helper,
  className,
  restrictedOption,
}) => {
  const fieldRef = useRef(null)
  let fieldValues = values || value

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" })
  }

  useEffect(() => {
    if (isFollowUpQuestion) handleScrollCallback()
  }, [])

  // Custom handleChange of every checkbox in the group
  //
  // Note: Curried function: need the form from the Field and option (text from values)
  //       to return then (event) => { ... }
  const handleChange = (form, option, index) => (event) => {
    const { setFieldValue } = form
    let newValue = [...fieldValues]
    if (event.target.checked) newValue.push(option)
    else newValue = newValue.filter((element) => element !== option)

    setFieldValue(name, newValue)
    if (onChange) onChange(event)
  }

  let disabledOptions = []

  if (!!restrictedOption) {
    if (fieldValues.includes(restrictedOption)) {
      fieldValues = [restrictedOption]
      disabledOptions = options.filter((option) => option !== restrictedOption)
    } else if (
      !fieldValues.includes(restrictedOption) &&
      fieldValues.length > 0
    ) {
      disabledOptions = [restrictedOption]
    }
  }

  const CheckBox = ({ form, option, index }) => (
    <span>
      <input
        className="is-checkradio"
        type="checkbox"
        checked={fieldValues.find((element) => element === option)}
        onChange={handleChange(form, option)}
        name={`${name}[${index}]`}
        id={`${name}[${index}]`}
        disabled={
          disabledOptions?.find((element) => element === option) || disabled
        }
      />
      <label
        className={classNames("checkbox-label", styles["form__checkboxLabel"])}
        for={`${name}[${index}]`}
      >
        <span dangerouslySetInnerHTML={{ __html: option }} />
      </label>
    </span>
  )

  return (
    <div className={classNames("mb-2", className || "")}>
      {title && (
        <label
          className={classNames("label has-text-weight-normal")}
          ref={fieldRef}
        >
          {title}{" "}
          {!isRequired && !hideOptional && (
            <span className="has-text-grey is-italic"> (Optional)</span>
          )}
        </label>
      )}
      {!!helper && (
        <span className={classNames("help has-text-weight-normal mb-1")}>
          {helper}
        </span>
      )}
      <FieldArray name={name}>
        {() =>
          options.map((option, index) => (
            <div key={index} className="mb-1">
              <Field>
                {({ form }) => (
                  <CheckBox form={form} option={option} index={index} />
                )}
              </Field>
              <div className={classNames("px-1", styles["backgroundAnimated"])}>
                {followUpQuestions.map((followUpQuestion) => {
                  const getFormField = getFollowUpQuestionData({
                    followUpQuestion,
                    formFields: formFields,
                  })
                  if (
                    fieldValues.includes(option) &&
                    getFormField?.referenceAnswer === camelize(option)
                  )
                    return (
                      <div className="notification is-light ml-2 mt-1">
                        {generateFormField({
                          formField: { ...getFormField, disabled: disabled },
                          formFields,
                          values: formValues,
                          setFieldValue,
                        })}
                      </div>
                    )
                })}
              </div>
            </div>
          ))
        }
      </FieldArray>
      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={name} />
      </p>
    </div>
  )
}

export default FormCheckbox
