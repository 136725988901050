import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faBuilding,
  faHospital,
} from "@fortawesome/free-solid-svg-icons"

import { ErrorMessage } from "formik"

const addressTypes = [
  {
    value: "Home",
    icon: faHome,
  },
  {
    value: "Office",
    icon: faBuilding,
  },
  {
    value: "Hospital",
    icon: faHospital,
  },
]

const AddressTypeChoices = ({ addressTypeName, values, setFieldValue }) => (
  <div className="mb-1">
    <label>Address Type</label>
    <div className={classNames("buttons mt-1")}>
      {addressTypes.map((addressType) => (
        <button
          onClick={() => {
            setFieldValue(addressTypeName || "addressType", addressType.value)
          }}
          className={classNames("button", {
            "is-primary":
              values?.addressType.toUpperCase() ===
              addressType.value.toUpperCase(),
          })}
          type="button"
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={addressType.icon} />
          </span>
          <span>{addressType.value}</span>
        </button>
      ))}
    </div>
    <ErrorMessage name={addressTypeName}>
      {(error) => <div className="has-text-danger is-size-7">{error}</div>}
    </ErrorMessage>
  </div>
)

export default AddressTypeChoices
