import React, { Fragment, useContext, useEffect } from "react"
import classNames from "classnames"
import { isEqual } from "lodash"

import CourierOption from "./CourierOption"
import PaymentOptions from "./PaymentOptions"

import { useShippingOptionsImages } from "../hooks/useShippingOptionsImages"
import { AppContext } from "../../../context/AppContext"
import Message from "../../Elements/Message"
import { ErrorMessage } from "formik"
import regularOrderCourierOptions from "../utils/regularOrderCourierOptions.json"
import specialOrderCourierOptions from "../utils/specialOrderCourierOptions.json"
import styles from "../utils/epharmacy.module.scss"

const CourierOptions = ({
  values,
  isNationwide,
  setFieldValue,
  nextRoute,
  configurationChannels,
  module,
}) => {
  const data = useShippingOptionsImages()
  let courierOptions = nextRoute?.includes("special-order")
    ? specialOrderCourierOptions
    : regularOrderCourierOptions
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy } = state
  const province = epharmacy?.deliveryAddress?.province.value
  const city = epharmacy?.deliveryAddress?.city.value
  const { medicines, patients } = epharmacy
  const medicineSKUs =
    patients?.length > 0
      ? patients
          ?.reduce(
            (allMedicines, currentPatient) =>
              allMedicines.concat(currentPatient?.medicines),
            []
          )
          ?.map((medicine) => medicine?.productTitle)
      : medicines?.map((medicine) => medicine?.productTitle)
  const filteredCourierOptions = courierOptions.filter((courierOption) => {
    const MEDEXPRESS_OPTION = "medexpress"
    let productLocations =
      Object.keys(courierOption?.inclusions?.productLocations || {}) || []

    let includedMedicines =
      courierOption?.inclusions?.products?.find?.(
        (product) => product?.isNationwide === isNationwide
      )?.medicines || []
    let filteredIncludedMeds = medicineSKUs?.filter((medicine) =>
      includedMedicines?.length > 0
        ? !includedMedicines?.includes(medicine)
        : false
    )
    let excludedMeds =
      medicineSKUs?.filter((medicine) =>
        courierOption?.exclusions?.products?.includes(medicine)
      ) || []

    if (productLocations?.length > 0) {
      let includedInProductLocations = productLocations?.filter(
        (medicine) => !!medicineSKUs?.includes(medicine)
      )

      let productNotCoveredInLocation = includedInProductLocations?.filter(
        (productLocationMedicine) => {
          return !courierOption?.inclusions?.productLocations?.[
            productLocationMedicine
          ]?.province?.includes(province)
        }
      )
      if (productNotCoveredInLocation?.length > 0) return false
    }

    if (excludedMeds?.length > 0) return false
    if (filteredIncludedMeds?.length > 0) return false
    if (courierOption?.name === MEDEXPRESS_OPTION) return false
    if (
      isNationwide &&
      !courierOption.isNationwide &&
      !courierOption?.inclusions?.province
    )
      return false
    if (courierOption?.exclusions?.province?.includes?.(province)) return false
    if (
      configurationChannels.length > 0 &&
      !configurationChannels.includes(courierOption?.name)
    )
      return false

    if (
      courierOption?.inclusions?.city?.[province]?.length > 0 &&
      !courierOption?.inclusions?.city?.[province]?.includes(city)
    ) {
      return false
    }

    if (
      courierOption?.inclusions?.province?.length > 0 &&
      !courierOption?.inclusions?.province.includes(province)
    )
      return false
    return true
  })

  useEffect(() => {
    let filteredCourierOptionNames = filteredCourierOptions.map(
      (courierOption) => courierOption.name
    )
    let filteredCourierOptionLabels = filteredCourierOptions.map(
      (courierOption) => courierOption.label
    )
    if (!isEqual(filteredCourierOptionLabels, epharmacy?.dispensingChannels)) {
      dispatch({
        type: "SAVE_DISPENSING_CHANNELS",
        payload: [...filteredCourierOptionLabels],
      })
    }
    let hasMedGrocerOption = filteredCourierOptionNames?.includes("medgrocer")
    if (hasMedGrocerOption !== epharmacy?.hasMedGrocerOption)
      dispatch({
        type: "SAVE_HAS_MEDGROCER_OPTION",
        payload: {
          hasMedGrocerOption,
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCourierOptions])

  return (
    <Fragment>
      <div
        className={classNames(
          "is-flex",
          styles["shippingDetails__shippingOptions"]
        )}
      >
        {filteredCourierOptions.map((courierOption) => {
          return (
            <Fragment>
              <CourierOption
                setFieldValue={setFieldValue}
                values={values}
                courierOption={courierOption}
                data={data}
              />
              {values.courierOption === courierOption.value && (
                <PaymentOptions
                  courierOption={courierOption}
                  values={values}
                  setFieldValue={setFieldValue}
                  data={data}
                  isNationwide={isNationwide}
                  module={module}
                />
              )}
              {courierOption?.notes?.length > 0 &&
                values.courierOption === courierOption.value && (
                  <Message color="warning" className="ml-1">
                    {courierOption.notes.map((note) => (
                      <p className="is-size-6">{note}</p>
                    ))}
                  </Message>
                )}
            </Fragment>
          )
        })}
      </div>
      <p className="help mt-0 mb-1 ml-1 is-danger">
        <ErrorMessage name="courierOption" />
      </p>
    </Fragment>
  )
}

export default CourierOptions
