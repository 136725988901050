import { useStaticQuery, graphql } from "gatsby"

const ALL_HOLIDAYS = graphql`
  {
    holidays: allAirtableHolidays {
      nodes {
        data {
          recordID: Record_ID
          name: Name
          holidayDate: Holiday_Date
        }
      }
    }
  }
`

export const useHolidays = () => {
  const data = useStaticQuery(ALL_HOLIDAYS)

  return data
}
