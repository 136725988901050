import React, { Fragment, useContext } from "react"
import classNames from "classnames"

import styles from "../utils/epharmacy.module.scss"
import { generateFollowUp } from "../utils/paymentOptions"
import PaymentOption from "./PaymentOption"
import { AppContext } from "../../../context/AppContext"
import { ErrorMessage } from "formik"

import {
  accumulatePrice,
  generateDeliveryFee,
  formatPrice,
} from "../services/computations"
import { getCouponDiscountAmount } from "../services/coupon"
import { parse } from "flatted"

const PaymentOptions = ({
  courierOption,
  values,
  setFieldValue,
  data,
  isNationwide,
  module,
}) => {
  const { state } = useContext(AppContext)
  const { epharmacy } = state
  const province = epharmacy?.deliveryAddress?.province.value
  const city = epharmacy?.deliveryAddress?.city.value
  const { medicines, patients, coupon } = epharmacy
  const medicineSKUs =
    patients?.length > 0
      ? patients
          ?.reduce(
            (allMedicines, currentPatient) =>
              allMedicines.concat(currentPatient?.medicines),
            []
          )
          ?.map((medicine) => medicine?.productTitle)
      : medicines?.map((medicine) => medicine?.productTitle)
  
  const specialOrderMedicines = patients?.reduce(
    (allMedicines, currentPatient) =>
      allMedicines.concat(currentPatient?.medicines),
    []
  )

  const allMedicines = module === "Special Order" ? specialOrderMedicines : medicines

  let couponDiscount = getCouponDiscountAmount(
    coupon,
    allMedicines,
    generateDeliveryFee()
  )

  const orderSubtotal = formatPrice({
    priceString: (
      accumulatePrice(allMedicines) +
      generateDeliveryFee() -
      couponDiscount
    ).toFixed(2)
  })
  let cleanedSubtotal = orderSubtotal.replace(/,/g, '');

  return (
    <div
      className={classNames(
        "notification is-light mb-2 ml-1 px-0",
        styles["shippingDetails__paymentOptions"]
      )}
    >
      {courierOption?.paymentMethods?.map((paymentOption) => {
        let hasExcludedMeds = paymentOption?.exclusions?.medicines?.filter(
          (exclude) => medicineSKUs.includes(exclude)
        )

        if (
          (isNationwide && !paymentOption.isNationwide) ||
          hasExcludedMeds?.length > 0
        )
          return null
        if (
          courierOption?.name === "medgrocer" &&
          paymentOption?.label === "Cash on Delivery" && 
          parseFloat(cleanedSubtotal) > 100000
        ) {
          return null
        }
          if (
          paymentOption?.inclusions?.city?.[province]?.length > 0 &&
          !paymentOption?.inclusions?.city?.[province]?.includes(city)
        ) {
          return null
        }

        if (
          paymentOption?.inclusions?.province?.length > 0 &&
          !paymentOption?.inclusions?.province.includes(province)
        )
          return null
        return (
          <Fragment>
            <PaymentOption
              values={values}
              paymentOption={paymentOption}
              setFieldValue={setFieldValue}
              data={data}
            />
            {values.paymentOption === paymentOption.label &&
              paymentOption?.followUps?.map((followUp) => (
                <div className="notification has-background-white mx-3 mx-1-mobile">
                  {generateFollowUp({ followUp, values })}
                </div>
              ))}
          </Fragment>
        )
      })}
      <p className="help mt-0 mb-1 ml-2 is-danger">
        <ErrorMessage name="paymentOption" />
      </p>
    </div>
  )
}

export default PaymentOptions
