import React from "react"
import classNames from "classnames"

import RadioContainer from "./RadioContainer"

const CourierOption = ({ setFieldValue, values, courierOption, data }) => {
  const handleClick = () => {
    setFieldValue("courierOption", courierOption.value)
    setFieldValue("paymentOption", "")
    setFieldValue("changeFor", "")
  }

  return (
    <RadioContainer
      isActive={values.courierOption === courierOption.value}
      onClick={handleClick}
      label={courierOption.label}
      logo={data[courierOption.name]?.childImageSharp.fixed}
    >
      {courierOption.description.map((description, index) => (
        <p
          className={classNames("is-size-6 is-size-7-mobile", {
            "mt-1 is-hidden-mobile": index > 0,
            "is-size-7": courierOption.description.length > 1,
          })}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ))}
    </RadioContainer>
  )
}

export default CourierOption
