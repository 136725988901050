import * as Yup from "yup"

const yearNow = new Date(Date.now()).getFullYear()

const REQUIRED_MESSAGE = "This field is required"
const VALID_YEAR_MESSAGE = "Please input a valid year"
const GENERIC_OPTION_NAME =
  "(Recommended) Best dispensing channel with available stocks and can deliver on your preferred time"

export const initialValues = {
  paymentOption: "",
  changeFor: "",
  courierOption: "",
  preferredCourierOption: GENERIC_OPTION_NAME,
  isSameDay: "",
  preferredDeliveryDate: {
    month: {
      value: null,
    },
    date: {
      value: null,
    },
    year: {
      value: yearNow,
      label: yearNow,
    },
  },
  preferredDeliveryTime: "",
}

export const validationSchema = ({ isNationwide, isSpecialOrder }) => {
  const baseSchema = {
    dispensingChannel: Yup.string().required("This field is required"),
    otherDispensingChannel: Yup.string().when("dispensingChannel", {
      is: (dispensingChannel) =>
        dispensingChannel ===
        "(Recommended) Switch to another dispensing channel",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
    preferredDeliveryDate: Yup.object({
      month: Yup.object().shape({
        value: Yup.string().required(REQUIRED_MESSAGE).nullable(),
      }),
      date: Yup.object().shape({
        value: Yup.number().required(REQUIRED_MESSAGE).nullable(),
      }),
      year: Yup.object().shape({
        value: Yup.number()
          .min(yearNow, VALID_YEAR_MESSAGE)
          .required(REQUIRED_MESSAGE),
      }),
    }),
    preferredDeliveryTime: Yup.string().required(REQUIRED_MESSAGE),
    courierOption: Yup.string().required(REQUIRED_MESSAGE),
    paymentOption: Yup.string().required(REQUIRED_MESSAGE),
    changeFor: Yup.string().when("paymentOption", {
      is: "Cash on Delivery",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
    creditCardBank: Yup.string().when(["paymentOption", "courierOption"], {
      is: (paymentOption, courierOption) =>
        paymentOption === "Credit Card Upon Delivery" &&
        courierOption === "MedGrocer",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
    creditCardType: Yup.string().when(["paymentOption", "courierOption"], {
      is: (paymentOption, courierOption) =>
        paymentOption === "Credit Card Upon Delivery" &&
        courierOption === "MedGrocer",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }

  if (!isNationwide) {
    baseSchema.bankName = Yup.string().when("paymentOption", {
      is: "Bank Transfer/Deposit",
      then: Yup.string().required(REQUIRED_MESSAGE),
    })
  }

  if (!isSpecialOrder) {
    baseSchema.rating = Yup.string().required(REQUIRED_MESSAGE);
    baseSchema.feedback = Yup.string().required(REQUIRED_MESSAGE);
  }

  return Yup.object().shape(baseSchema);
}
