import moment from "moment"
import React, { Fragment } from "react"
import FormDateV2 from "../../Elements/Form/FormDateV2"
import FormInput from "../../Elements/Form/FormInput"
import Section from "../../Elements/Section"

const PreferredDeliveryScheduleField = ({
  values,
  handleChange,
}) => {
  let earliestDeliveryDate = moment()

  if (values?.deliveryAddress?.addressType === "Office")
    earliestDeliveryDate = earliestDeliveryDate.add(1, "days")

  return (
    <Fragment>
      <FormDateV2
        name="preferredDeliveryDate"
        label="Preferred Delivery Date"
        isRequired
        values={values?.preferredDeliveryDate}
        onChange={handleChange}
        config={{
          range: {
            minDate: earliestDeliveryDate.format("YYYY-MM-DD"),
            maxDate: moment().add(12, "months").format("YYYY-MM-DD"),
          },
          weekdays: true,
        }}
      />
      <FormInput
        name="preferredDeliveryTime"
        label="Preferred Delivery Time"
        isRequired
      />
    </Fragment>
  )
}

const DeliveryScheduleSection = ({
  isNationwide,
  values,
  handleChange,
}) => {
  return (
    <Section title="Delivery Schedule" className="my-1">
      <PreferredDeliveryScheduleField
        isNationwide={isNationwide}
        values={values}
        handleChange={handleChange}
      />
    </Section>
  )
}

export default DeliveryScheduleSection
